import React from 'react';
import axios from 'axios'


export const sendEmail = async (data) => {
  axios.post('https://homaly.herokuapp.com/email4s2000/tudasbazis4s2000EMail', {
    toEmail : data.email,
    title : data.title,
    text : data.text
  })
  .then(response => {
    console.log('EMAIL SENT')
    return response
  })
  .catch( error => {
    console.log(error);
    return error
  });

  
};



function EmailOp(props) {

  
 
  return (
    <div >

    </div>
    );

  }


export default EmailOp;
