import React, { useEffect, useState, useContext  } from 'react';
import home from '../assets/home.jpg'
import '../App.css';
import { appContext } from '../App'
import { NavLink } from 'react-router-dom';



function HomeScreen(props){

    const { history } = props

    //appContext
    const [ appContactList, setAppContactList, actualUser, setActualUser, actualName, setActualName, updaterId, setUpdaterId, popup, setPopup ] = useContext(appContext)

    const loginClick = (value) => {
        const { history } = props;
        history.push('login');
      }

    return(
        <div className = 'appContainer' >
            <div className = 'menuHomeContainer' >
                <NavLink to="/login" className="NavLink">BEJELENTKEZÉS</NavLink>
            </div>
            <div className = 'imageContainer' >
                <img className = 'image'  src={home} alt="home1"></img>
            </div>
        </div>
    )
}

export default HomeScreen