import React, { useEffect, useState, useContext, Fragment  } from 'react';
import uploadPic from '../assets/upload.jpg'
import '../App.css';
import { appContext } from '../App'
import Uploader from '../components/Uploader'
import UploaderJson from '../components/UploaderJson'
import Menu from '../components/Menu'



function UploadScreen(props){

  const { history } = props

  //appContext
  const [ 
    appContactList, setAppContactList, actualUser, setActualUser, actualName, setActualName, updaterId, setUpdaterId, popup, setPopup, popupVisibility, setPopupVisibility,
        popupMessage, setPopupMessage, pdfList, setPdfList
  ] = useContext(appContext)

  useEffect(() => {

  }, [])

  return(
      <div className = 'uploadContainer'>
        {console.log('upload actualuser ', actualUser)}
        <div className = 'menuContainer' >
          <Menu history = {history}></Menu>
        </div>
        <div className = 'searchWrapper' >
          <div className = 'searchContainer' >
            <Uploader history = {history}></Uploader>
          </div>
          {actualUser.email === 'szempontstudio@gmail.com' 
            ? <div className = 'searchContainer' ><UploaderJson history = {history}></UploaderJson></div>
            : ''
          }
        </div>
        <div className = 'imageContainer' >
          <img className = 'image'  src={uploadPic} alt="home1"></img>
        </div>
      </div>
    )
}

export default UploadScreen