import React, { useEffect, useState, useContext  } from 'react';
import searchPic from '../assets/search.jpg'
import '../App.css';
import { appContext } from '../App'
import Menu from '../components/Menu'
import pdfIcon from '../assets/pdf.png'
import CustomSelect from '../components/CustomSelect'






function SearchScreen(props){

  const { history } = props

    //appContext
    const [  appContactList, setAppContactList, actualUser, setActualUser, actualName, setActualName, updaterId, setUpdaterId, popup, setPopup, popupVisibility, setPopupVisibility,
      popupMessage, setPopupMessage, dataList, setDataList, jsonList, setJsonList, jsonTextList, setJsonTextList, searchResultList, setSearchResultList 
      ] = useContext(appContext)

    const options=[
      {label:'rendszer'}, {label:'modell'}, {label:'definíció'}, {label:'folyamat'}, {label:'definiálás'}, {label:'system'}, {label:'engineering'}, {label:'process'},
      {label:'overview'}, {label:'waterfall'}, {label:'agile'}, {label:'development'}, {label:'requirement'}, {label:'követelmény'}, {label:'igény'}, {label:'rendszerkövetelmény'},
      {label:'jellemző'}, {label:'tartalmi'}, {label:'formai'}, {label:'funkció'}, {label:'functional'}, {label:'célrendszer'}, {label:'deduktív'}, {label:'modellalkotás'},
      {label:'induktív'}, {label:'eszköz'}, {label:'unified'}, {label:'language'}, {label:'lifecycle'}, {label:'analizálás'}, {label:'analysis'}, {label:'features'},
      {label:'concept'}, {label:'modellezés'}, {label:'módszer'}, {label:'tervezés'}, {label:'algoritmizálás'}, {label:'áramlástechnika'}, {label:'komplex'}, {label:'definíció'},
      {label:'tényező'}, {label:'alternatívák'}, {label:'értékelés'}, {label:'súlyozás'}, {label:'rangsorolás'}, {label:'ipar'}, {label:'integrált'}, {label:'hálózat'},
      {label:'kommunikáció'}, {label:'gyártás'}, {label:'smart'}, {label:'manufacturing'}, {label:'szabvány'}, {label:'specification'},
      {label:'standard'}, {label:'method'}, {label:'formula'}, {label:'framework'}, {label:'quality'}, {label:'technológia'}, {label:'okozat'},
      {label:'pump'}, {label:'suction'}, {label:'specifications'}, {label:'ágens'}, {label:'robotizálás'}, {label:'testing'},
      {label:'management'}, {label:'üzemeltetés'}, {label:'aktivitás'}, {label:'géphasználat'}, {label:'karbantartás'}, {label:'gépesítés'}, {label:'szivattyú'},
      {label:'aggregát'}, {label:'centrifugal'}, {label:'problem'}, {label:'suction'}, {label:'viszkozitás'}, {label:'slurry'}, {label:'zagy'},
      {label:'classification'}, {label:'efficiency'}, {label:'szállítómagasság'}, {label:'hajtásátvitel'}, {label:'curve'}, {label:'measuring'}, {label:'metrology'}, {label:'módszertan'},
      {label:'intuíció'}, {label:'graph'}, {label:'interconnection'}, {label:'failures'}, {label:'megbízhatóság'}, {label:'reliability'}, {label:'maintainability'}, 
      {label:'kopás'}, {label:'dinamikus'}, {label:'öregedés'}, {label:'ráta'}, {label:'availability'}, {label:'range'}, {label:'modularity'}, {label:'rendelet'}, 
      {label:'szabvány'}, {label:'innováció'}, {label:'gömbszivattyú'}, {label:'spherical'}, {label:'trend'}, {label:'reláció'}, {label:'intelligens'}, {label:'dolgok'},
      {label:'görbék'}, {label:'vezeték'}, {label:'mérőhálózat'}, {label:'iker'}, {label:'twins'}, {label:'appendix'}
    ]

    let foundList = []

    useEffect(() => { }, [jsonList, searchResultList])


    const handleDropdown = (value) => {
      console.log(value);
      search(value)
    }

    const search = (dropdownValue) => {
      setSearchResultList([])
      let searchWord = dropdownValue.label.toString()
      foundList.lentgh = 0
      Object.keys(dataList).map(id =>{
        if(dataList[id].jsonname.toString().toLowerCase().includes(searchWord)){
          setSearchResultList(searchResultList => [...searchResultList, dataList[id]])
        }
      })
      
  }

    const clickPdf = (pdfName) => { window.open('https://homalyzona.herokuapp.com/s2000data/showpdf?filename=/s2000pdf/' + pdfName, '_blank') }

    return(
        <div className = 'appContainer' >
            <div className = 'menuContainer' >
              <Menu history = {history}></Menu>
            </div>
            <div className = 'search'>
              <h1>Keresőszó-találati lista</h1>
              <div className = 'contentTable'>
               
            {Object.keys(searchResultList).map(id =>{
              return <div key = {id} >
                <div className = 'contentRow' key = {id}>
                    <div id = 'contentPic'><img id = 'contentPicture' src = {pdfIcon}></img></div>
                    <div id = 'contentTitle'><h2 onClick={(e) => clickPdf(searchResultList[id].pdfname)} >{searchResultList[id].pdfname}</h2></div>
                </div>
              </div>
              })}
               
              </div>
            </div>
            <div className = 'searchDropdown' >
              <CustomSelect onChange={handleDropdown} options={options}/>
            </div>
            <div className = 'imageContainer' >
                <img className = 'image'  src={searchPic} alt="home1"></img>
            </div>
        </div>
    )
}

export default SearchScreen