import React, { useEffect, useState, useContext  } from 'react';
import { NavLink } from 'react-router-dom';
import { appContext } from '../App'
import '../App.css';
import fire from '../database/Firebase'
import { saveContact } from '../database/DatabaseOp'
import { sendEmail } from '../service/EmailOp'
import register from '../assets/register.jpg'
import Menu from '../components/Menu'

let ref = fire.database().ref('contacts')
let emailObject = {}

const RegisterScreen = (props) => {

  const { history } = props

  //appContext
  const [ appContactList, setAppContactList, actualUser, setActualUser, actualName, setActualName, updaterId, setUpdaterId, popup, setPopup ] = useContext(appContext)
    
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [nameError, setNameError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');


  useEffect(() => {
    clearErrors()
    clearInputs()
  }, [])

  const clearErrors = () => {
    setNameError('')
    setEmailError('')
    setPasswordError('')
  }

  const clearInputs = () => {
    setName('')
    setEmail('')
    setPassword('')
  }

  const handleEmail = (emailObject) => {
    console.log('register handleemail ', emailObject)
    sendEmail(emailObject)
    .then(data => console.log('SENDEMAIL resolved ', data))
    .catch(err => console.log('SENDEMAIL rejected ', err))
  }

  const saveUser = (value) => {     
    let regUser = {}
      regUser.name = name
      regUser.email = email
      regUser.password = password
      regUser.role = 'regisztrált'
    saveContact(ref, regUser)
      .then((response) => { console.log('register savecontact', response) })
      .catch((error) => { console.log('app error', error) });
    //regisztrációs email usernek
    emailObject.email = regUser.email
    emailObject.title = 'Sikeres regisztráció a 4s2000 tudásbázis oldalon'
    emailObject.text = 'Ön regisztrált a 4S-2000 Kft., "Speciális térfogat-változtató forgógép létrehozása" című, 2019-1.1.1-PIACI-KFI-2019-00429 azonosító számú projektjének keretében létrehozott, "szivattyú működését akadályozó tényezők feltárását támogató tudásbázis" hozzáférése érdekében. Regisztrációjának adminisztrátori jóváhagyásáról e-mailben fogjuk értesíteni.'
    handleEmail(emailObject)
    //regisztrációs email adminnak
    emailObject.email = 'szempontstudio@gmail.com'
    emailObject.title = '4s2000 tudásbázis - új felhasználó'
    emailObject.text = 'Új regisztráció a 4s2000 tudásbázis oldalon! Adminisztrátori jóváhagyás szükséges!'
    handleEmail(emailObject)
  }

  const handleRegister = (e) => {
    if(e){ e.preventDefault() }
    
    clearErrors()
    clearInputs()
  
    fire
      .auth()
      .createUserWithEmailAndPassword(email, password)
      .then((value) => { 
        {console.log('handleRegister then value ', value)}
        saveUser(value)
        {console.log('history.push(login); ')}
        const { history } = props;
        history.push('login'); })
      .catch((err) => {
        {console.log('handleRegister errorcode ', err.code)}
        {console.log('handleRegister errormessage ', err.message)}
        switch(err.code){
          case 'auth/email-already-in-use' :
            setEmailError('Ez az e-mail már regisztált a rendszerünkbe, kérem adjon meg egy másikat!')
            //setRepeat('auth/email-already-in-use')
            break
          case 'auth/invalid-email' :
            setEmailError('Nem megfelelő formátumú e-mail')
            //setRepeat('Nem megfelelő formátumú e-mail')
            break
          case 'auth/weak-password' :
            setPasswordError('A jelszónak legalább 6 karakternek kell lennie!')
            //setRepeat('auth/weak-password')
            break
        }
    }) 
  }


    return(
      <div className = 'appContainer'>
        <div className ='loginForm'>
        <form >
        <ul className="form-container">
          <li>
            <h2>Regisztráljon!</h2>
          </li>
          <li>
            <label htmlFor="name">
              Név
            </label>
            <input type="text" name="name" id="name" required onChange={(e) => setName(e.target.value)}>
            </input>
            <p className = 'errorMsg'>{nameError}</p>
          </li>
          <li>
            <label htmlFor="email">
              Email
            </label>
            <input type="email" name="email" id="email" required onChange={(e) => setEmail(e.target.value)}>
            </input>
            <p className = 'errorMsg'>{emailError}</p>
          </li>
          <li>
            <label htmlFor="password">Password</label>
            <input type="password" id="password" name="password" required onChange={(e) => setPassword(e.target.value)}>
            </input>
            <p className = 'errorMsg'>{passwordError}</p>
          </li>
          <li>
            <button type="button" onClick={(e) => handleRegister(e.target.value)} className="button primary">Regisztráció</button>
          </li>
          <li>
            Már van fiókja?
          </li>
          <li>
            <NavLink to= "login"  className="button secondary text-center" >Jelentkezzen be!</NavLink>
          </li>
        </ul>
        </form>
        </div>
        <div className = 'menuContainer' >
          <Menu history = {history}></Menu>
        </div>
        <div className = 'imageContainer' >
          <img className = 'image'  src={register} alt="home1"></img>
        </div>
      </div>
    )
}

export default RegisterScreen