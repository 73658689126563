import React from 'react'
import Select from 'react-select'



function CustomSelect(props){
    return <div className = 'searchDropdown'>
        <h3>Keresés</h3>
        <Select options={props.options} onChange={props.onChange} placeholder={'Válasszon keresőszót'}/>
    </div>
}

export default CustomSelect;