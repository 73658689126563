import React, { useEffect, useState, useContext, Fragment  } from 'react';
import '../App.css';
import { appContext } from '../App'


function Popup(props){

  //appContext
  const [ 
    appContactList, setAppContactList, actualUser, setActualUser, actualName, setActualName, updaterId, setUpdaterId, popup, setPopup, popupVisibility, setPopupVisibility,
    popupMessage, setPopupMessage
  ] = useContext(appContext)

  useEffect(() => {

  }, [])

  return(
      <div className = 'popup' id = {popup}>
        
          <h1>{popupMessage}</h1>
          <button className="popupButton" onClick={(e) => props.popupHide()}>OK</button>
      </div>
    )
}

export default Popup